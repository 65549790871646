import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import './assets/icons/phosphor.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import './assets/scss/educbank.scss'
// @ts-ignore
import JsonViewer from "vue3-json-viewer";
// @ts-ignore
import money from 'v-money'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { vMaska } from "maska/vue"
import App from './App.vue'
import router from './app/router/instance'
import educbank from '@/educbank'
import AthButton from '@/components/buttons/AthButton.vue'
import i18n from './i18n'
import installPlugins from './app/config/plugins'
import installDirectives from './app/config/directives'
import initializeProviders from './app/config/providers'
import './registerServiceWorker'

const pinia = createPinia()
const app = createApp(App)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(educbank)
  .use(JsonViewer)
  .use(money, { precision: 4 })
  .directive('maska', vMaska)
  .component('ath-button', AthButton)

initializeProviders()
installPlugins(app)
installDirectives(app)

app.mount('#app')
