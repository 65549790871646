import { defineStore } from "pinia";
import { StateUserField } from "../types/user";

export const useUserStore = defineStore('userStore', {
  state: (): StateUserField => {
    return {
      isAuthenticated: false,
      expired: true,
      userName: '',
      email: '',
      id: '',
      tenant: undefined,
      allTenants: { items: [], totalCount: 0 },
      tenantSelected: undefined,
      skipCountTenant: 0,
      maxResultCountTenant: 50,
      inGroupAdmin: false,
    }
  },
})
