import { useUserStore } from "@/modules/users/store/userStore";

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();

let features = {};

class Features {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct Feature Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new Features(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  registry(listOfFeatures) {
    features = listOfFeatures;
  }

  hasFeature(feature) {
    const userStore = useUserStore();
    const isAdminEducbank = userStore.email.indexOf('educbank.com.br') > -1;

    return features[feature] || isAdminEducbank;
  }
}

export default Features.instance;
