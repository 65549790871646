import { useUserStore } from "@/modules/users/store/userStore";

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();

let permissions = {};

class Permissions {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct Permission Instance');
    }
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new Permissions(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  registry(listOfPermissions) {
    permissions = listOfPermissions;
  }

  hasPermission(permission) {
    return permissions[permission] ?? false
  }

  isHost() {
    const userStore = useUserStore();
    return !userStore?.tenant?.id;
  }

  forChangeData() {
    const userStore = useUserStore();
    if (!this.isHost()) return true
    return userStore?.tenantSelected && userStore?.tenantSelected?.id
  }

  isTenantSelectedIsGlobal() {
    const userStore = useUserStore();
    return userStore?.tenantSelected && userStore?.tenantSelected?.name === 'Global'
  }
}

export default Permissions.instance;
