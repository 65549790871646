import permission from '@/app/permission';
import feature from '@/app/feature';
import setting from '@/app/setting';
import provider from '@/provider';
import i18n from '@/i18n';
import educbankService from '@/services/educbankService';
import localforage from 'localforage';
import subdomain from '@/helpers/subdomain';
import { capitalize } from '@/helpers/string';
import IntegrationOidc from '@/app/integrations/oidc';
import userService from "@/modules/users/services/userService";
import settingsService from "@/modules/configurations/parameters/services/settingsService";
import { useUserStore } from '@/modules/users/store/userStore';

class LoadBackendConfigurationError extends Error {
  constructor(message) {
    super(`\n Message: ${message} \n\n`);
    this.name = 'Educbank web template -> loadConfiguration: ';
  }
}

const service = async () => {
  try {
    const userStore = useUserStore();

    const response = await provider.core.get('/api/abp/application-configuration');
    const result = response.data;

    userStore.inGroupAdmin = await userService.userInGroupAdmin(result.currentUser.id)
    userStore.tenant = {
      ...result.currentTenant,
      currentTenantIsReadOnly: !!result?.extraProperties?.currentTenantIsReadOnly
    }

    await permission.registry(result.auth.grantedPolicies);
    feature.registry(result.features.values);

    const tenantLocalForage = JSON.parse(await localforage.getItem('tenantSelected'));

    if (permission.isHost() && tenantLocalForage?.id) {
      setting.registry(await settingsService.getSettingsForTenant(tenantLocalForage?.id));
    } else {
      setting.registry(result.setting.values);
    }

    i18n.global.locale.value = result.localization.currentCulture.cultureName;

    if (permission.isHost()) {
      if (permission.hasPermission('Educbank.Tenant.GetAll')) {
        await educbankService.getAllTenants(`?SkipCount=${userStore.skipCountTenant}&MaxResultCount=${userStore.maxResultCountTenant}`).then(async (tenants) => {
          tenants.items.unshift({ id: '', name: 'Global', displayName: 'Global' });
          if (tenantLocalForage) {
            const found = tenants.items.find(item => {
              return item.id === tenantLocalForage.id;
            });

            if (!found) {
              tenants.items.push(tenantLocalForage);
            }
            userStore.tenantSelected = tenantLocalForage;
          } else {
            userStore.tenantSelected = tenants.items[0];
          }

          userStore.allTenants = tenants
        });

        if (userStore.tenantSelected.name !== 'Global') {
          const response = await provider.core.get('/api/abp/application-configuration');
          const result = response.data;
          feature.registry(result.features.values);
        }
      } else {
        userStore.tenantSelected = { id: '', name: 'Global', displayName: 'Global' };
      }
    }

    if (userStore.tenantSelected) {
      document.title = userStore.tenantSelected.displayName || userStore.tenantSelected.name;
    } else {
      document.title = `${subdomain.replace('.', '')}`;
      document.title = capitalize(document.title);
    }
  } catch (e) {
    await IntegrationOidc.logout()
    throw new LoadBackendConfigurationError(`can't loadBackendConfigurations in app/config. \n\n${e}`);
  }
};

export default service;
