import axios from "axios";
import subdomain from "@/helpers/subdomain";
import { errorProvider } from "@/app/config/providers/error";
import IntegrationOidc from "@/app/integrations/oidc";
import permission from "@/app/permission";
import { useUserStore } from "@/modules/users/store/userStore";

const COURIER = axios.create({
  baseURL: `${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_PROVIDER_COURIER}`,
  headers: { 'Access-Control-Allow-Origin': '*' },
  withCredentials: true
});

COURIER.interceptors.response.use(response => response, function (error) {
  return errorProvider(error);
});

COURIER.interceptors.request.use(async (config) => {
  const tokenUser = await IntegrationOidc.Mgr().getUser()
  const userStore = useUserStore();

  if (tokenUser) {
    if (tokenUser.access_token) {
      const newToken = tokenUser.expired ? await IntegrationOidc.renewToken() : tokenUser.access_token
      config.headers.Authorization = `Bearer ${newToken}`;

      if (
        userStore.tenantSelected &&
        userStore.tenantSelected.id &&
        permission.isHost() &&
        !(/feature-management\/features/.test(config.url))
      ) {
        config.headers.tenantId = userStore.tenantSelected.id
        config.headers.tenantName = userStore.tenantSelected.name
      }
    }
  }
  return config;
},
  (err) => {
    console.log(`error interceptors provider COURIER`, err);
  }
);

export { COURIER }
