import Sweetalert2 from 'sweetalert2'
import i18n from '@/i18n'
import { applyPolyfills, defineCustomElements } from '@componentt/toast/loader'

applyPolyfills().then(() => {
  defineCustomElements()
})

import { Toast } from '@componentt/toast'
import helper from '@/helpers/helper'

const toast = new Toast({ theme: 'educbank', placement: 'top' })

const Alert = () => {
  return {
    simpleDialog: ({ title, position = 'center', width = '50%', icon = 'success', text = '' } = {}) =>
      Sweetalert2.fire({
        title: `${title}`,
        text,
        position,
        width,
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        customClass: { confirmButton: 'order-2' },
        icon,
      }),
    delete: (name) =>
      Sweetalert2.fire({
        title: `${i18n.global.t('AreYouSureYouWantToDelete')} ${name}?`,
        text: i18n.global.t('DeleteNotRevert'),
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: i18n.global.t('Cancel'),
        confirmButtonText: i18n.global.t('Delete'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
      }),
    confirm: ({
      title,
      subtitle = i18n.global.t('SubtitleConfirm'),
      html = undefined,
      icon = 'warning',
      textClose = 'Cancel',
      iconColor = undefined,
      width = '100%',
      textConfirm = 'YesContinue',
    } = {}) =>
      Sweetalert2.fire({
        title: `${title}`,
        html,
        text: subtitle,
        icon,
        showCancelButton: true,
        showCloseButton: false,
        iconColor,
        cancelButtonText: i18n.global.t(textClose),
        confirmButtonText: i18n.global.t(textConfirm),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
        width: width,
      }),
    newPhone: ({
      title = i18n.global.t('UnregisteredPhone'),
      subtitle = '',
      textConfirm = i18n.global.t('Confirm'),
      inputValue = '+55 ',
    } = {}) =>
      Sweetalert2.fire({
        title,
        text: subtitle,
        inputLabel: i18n.global.t('EnterYourPhone'),
        input: 'text',
        inputValue,
        width: '35%',
        showCancelButton: true,
        showCloseButton: true,
        inputValidator: (value) => {
          if (!helper.phoneValidate(value)) {
            return i18n.global.t('InvalidPhone')
          }
        },
        didOpen: function (el) {
          const element = el.querySelector('input[type=text]')
          element.addEventListener('keypress', function (e) {
            if (!/\d/.test(e.key)) {
              e.preventDefault()
            } else if (element.value.length === 0) {
              element.value = '+'
            } else if (element.value.length === 3 || element.value.length === 6) {
              element.value += ' '
            } else if (element.value.length === 12) {
              element.value += '-'
            } else if (element.value.length === 17) {
              e.preventDefault()
            }
          })
        },
        confirmButtonText: textConfirm,
        cancelButtonText: i18n.global.t('Close'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
      }),
    notify: (type, title, description, actions, progress = false, cooldown = 5000) => {
      toast.emit({ description, type, title, progress, cooldown, actions })
    },
    setReason: ({ title = i18n.global.t('Reason'), subtitle = i18n.global.t('EnterYourReason'), info = '' } = {}) =>
      Sweetalert2.fire({
        title,
        text: info,
        inputLabel: subtitle,
        input: 'textarea',
        showCancelButton: true,
        cancelButtonText: i18n.global.t('Cancel'),
        inputValidator: (value) => {
          if (!value || value.length <= 0) {
            return i18n.global.t('InvalidReason')
          }
        },
      }),
    setReasonText: ({ title = 'Reason', subtitle = 'EnterYourReason', info = '' } = {}) =>
      Sweetalert2.fire({
        title: title,
        text: info,
        inputLabel: subtitle,
        input: 'text',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: i18n.global.t('Cancel'),
        confirmButtonText: i18n.global.t('YesContinue'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
        inputValidator: (value) => {
          if (!value || value.length <= 0) {
            return i18n.global.t('InvalidReason')
          }
        },
      }),
    setConfirmationText: ({
      title = 'Reason',
      confirmText = i18n.global.t('Confirm'),
      info = '',
      html = undefined,
      icon = 'warning',
    } = {}) =>
      Sweetalert2.fire({
        title: title,
        text: info,
        html,
        icon,
        inputLabel: `${i18n.global.t('TypeIt')} ${confirmText}`,
        input: 'text',
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: i18n.global.t('Close'),
        confirmButtonText: i18n.global.t('Continue'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
        inputValidator: (value) => {
          if (value !== confirmText) {
            return i18n.global.t('InvalidInsertedValue')
          }
        },
      }),
    setLoginMfaDisable: (email) =>
      Sweetalert2.fire({
        title: i18n.global.t('MessageInfoMfaDisable'),
        inputLabel: `${i18n.global.t('MessageEnterPasswordMfaDisable')} (${email})`,
        position: 'center',
        icon: 'warning',
        iconColor: '#DF6962',
        input: 'password',
        width: '50%',
        showCancelButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: i18n.global.t('Cancel'),
        confirmButtonText: i18n.global.t('Confirm'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
        inputValidator: (value) => {
          if (!value || value.length < 6) {
            return i18n.global.t('ThisFieldIsRequired')
          }
        },
      }),
    selectOption: ({ title = 'Select a option', placeholder = 'Select', options = {} } = {}) =>
      Sweetalert2.fire({
        title: title,
        input: 'select',
        inputOptions: options,
        inputPlaceholder: placeholder,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: i18n.global.t('Cancel'),
        confirmButtonText: i18n.global.t('Update'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
      }),
    download: ({ title, subtitle = i18n.global.t('InvoicesDownloadText') } = {}) =>
      Sweetalert2.fire({
        title: title,
        text: subtitle,
        timer: 4000,
        icon: 'success',
        width: '30%',
        showCancelButton: true,
        showCloseButton: false,
        showConfirmButton: false,
        cancelButtonText: i18n.global.t('CloseAndWaitDownload'),
        customClass: { cancelButton: 'order-1 right-gap' },
      }),
    preview: ({
      title = i18n.global.t('Preview'),
      html = '',
      showConfirmButton = false,
      confirmButtonText = undefined,
      width = '100%',
    } = {}) =>
      Sweetalert2.fire({
        title: title,
        html: html,
        width: width,
        showCancelButton: false,
        showCloseButton: true,
        showConfirmButton: showConfirmButton,
        confirmButtonText: confirmButtonText,
      }),
    confirmInputRadio: ({ title, subtitle = '', inputOptions } = {}) =>
      Sweetalert2.fire({
        title,
        text: subtitle,
        input: 'radio',
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: i18n.global.t('NoClose'),
        confirmButtonText: i18n.global.t('Confirm'),
        customClass: { cancelButton: 'order-1 right-gap', confirmButton: 'order-2' },
        inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return i18n.global.t('SelectAtLeastOneOption')
          }
        },
      }),
  }
}

export default Alert()
