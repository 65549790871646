import subdomain from "@/helpers/subdomain";
import loadBackendConfiguration from "@/app/config/loadBackendConfiguration";
import signalr from "@/app/integrations/signalr";
import localforage from 'localforage';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { useUserStore } from "@/modules/users/store/userStore";

const mgr = new UserManager({
  revokeTokenTypes: ["refresh_token", "access_token"],
  authority: `${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_PROVIDER_AUTH}`,
  client_id: process.env.VUE_APP_IDENTITY_CLIENT_ID,
  client_secret: process.env.VUE_APP_IDENTITY_CLIENT_SECRET,
  redirect_uri: `${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_SELF_URL}/signin-callback`,
  post_logout_redirect_uri: `${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_SELF_URL}/`,
  response_type: 'code',
  scope: process.env.VUE_APP_IDENTITY_SCOPE,
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 60,
  userStore: new WebStorageStateStore({ store: window.localStorage })
});
mgr.startSilentRenew()
export default class SecurityService {
  static async authenticate(returnPath, query) {
    const user = await mgr.getUser();
    const userStore = useUserStore();

    if (user && !user.expired) {
      userStore.isAuthenticated = true;
      userStore.expired = user.expired;
      userStore.userName = user.profile.given_name;
      userStore.email = user.profile.email;
      userStore.id = user.profile.sub;

      await loadBackendConfiguration();
      await signalr.initConnections()
      return
    }
    await this.signIn(returnPath, query);
  }
  static signIn(returnPath, query) {
    if (returnPath)
      mgr.signinRedirect({ state: returnPath, extraQueryParams: { client: query } })
    else
      mgr.signinRedirect();
  }
  static async logout() {
    await localforage.clear();
    mgr.getUser().then(async user => {
      await mgr.revokeTokens(mgr.revokeTokenTypes)
      await mgr.clearStaleState();
      await mgr.removeUser();
      const userStore = useUserStore();

      userStore.isAuthenticated = false;

      await mgr.signoutRedirect({
        id_token_hint: user?.id_token,
        post_logout_redirect_uri: `${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_SELF_URL}/`
      });
    });
  }
  static Mgr() {
    return mgr;
  }
  static async renewToken() {
    const userGet = await mgr.getUser();
    if (userGet.expired) {
      const self = this
      return new Promise((resolve, reject) => {
        mgr.signinSilent().then(function (user) {
          if (user == null) {
            self.signIn(null)
          } else {
            return resolve(user)
          }
        }).catch(function (err) {
          console.log(err)
          return reject(err)
        });
      })
    }
  }
}
