import provider from '@/provider';

export interface UserCore {
	id?: string
	name: string
	creationTime?: string
	lastModificationTime?: string
	userName: string
	surname?: string
	email?: string
	emailConfirmed?: boolean
	phoneNumber?: string
	tenantId?: string
	roleNames?: string[]
	isActive?: boolean
	enabledMfaProviders?: string[]
}

export interface RoleUserCore {
	id: string
	name: string
}

const service = () => {
	return {
		baseUrl: '/api/ebpay/user',

		async changePassword(currentPassword: string, newPassword: string) {
			const response = await provider.core.post(`/api/ebpay/user/change-password`, {
				currentPassword: currentPassword,
				newPassword: newPassword
			});
			return response.data;
		},

		async create(user: UserCore) {
			return provider.core.post(this.baseUrl, {
				userName: user.userName,
				name: user.name,
				surname: user.surname,
				email: user.email,
				phoneNumber: user.phoneNumber,
				roleNames: user.roleNames,
				isActive: user.isActive,
				document: {
					value: null,
				}
			});
		},

		async createDetailedUser(user: any) {
			return provider.core.post(this.baseUrl, user);
		},

		async updateDetailedUser(user: any) {
			return provider.core.put(`${this.baseUrl}/${user.id}`, user);
		},

		async updateDetailedUserMyAccount(user: any) {
			return provider.core.post(`${this.baseUrl}/self-update`, user);
		},

		async getById(id: string): Promise<UserCore> {
			const response = await provider.core.get(`${this.baseUrl}/${id}`)
			return response.data;
		},

		async delete(id = '') {
			return provider.core.delete(`${this.baseUrl}/${id}`);
		},

		async edit(user: UserCore) {
			const userUpdated = {
				userName: user.userName,
				name: user.name,
				surname: user.surname,
				email: user.email,
				phoneNumber: user.phoneNumber,
				roleNames: user.roleNames,
				isActive: user.isActive,
				enabledMfaProviders: user.enabledMfaProviders,
				document: {
					value: null,
				}
			}

			const response = await provider.core.put(`${this.baseUrl}/${user.id}`, { ...userUpdated });
			return response.data;
		},

		async getRolesById(id = ''): Promise<RoleUserCore[]> {
			const response = await provider.core.get(`${this.baseUrl}/${id}/roles`);
			return response.data.items;
		},

		async createPassword(userId: string, token: string, password: string) {
			return provider.core.post(`${this.baseUrl}/set-pwd-to-first-login`, {
				userId: userId,
				resetToken: token,
				password: password,
			});
		},

		async configureTwoFactor(userId: string, tokenProvider: string) {
			return provider.identity.post(`api/identity/users/${userId}/mfa/configure/${tokenProvider}`);
		},

		async validateTwoFactor(userId: string, token: string, tokenProvider: string) {
			return provider.identity.post(`api/identity/users/${userId}/mfa/validate/${tokenProvider}`, { token });
		},

		async disableTwoFactor(tokenProvider: string, login: string, password: string) {
			return provider.identity.post(`api/identity/users/mfa/disable/${tokenProvider}`, {
				userNameOrEmailAddress: login,
				password: password,
			});
		},

		async resendFirstEmailLogin(userEmail: string) {
			return provider.core.get(`api/ebpay/resend-first-login-email/${userEmail}`);
		},

		async userInGroupAdmin(userId: string): Promise<boolean> {
			const response = await provider.core.get(`api/identity/users/${userId}/roles`);
			return response.data.items.some((role: any) => role.name === 'admin');
		},

		async userGetIdentity(userId: string): Promise<any> {
			const response = await provider.core.get(`api/identity/users/${userId}`);
			return response.data
		},
	}
}

export default service();
